import React, { lazy, Suspense, useEffect } from 'react';
import Buffering from './Buffering';
// import AboutUsContent from './AboutUsContent';
// import PageHeader from './PageHeader';

const PageHeader = lazy(() => import('./PageHeader'));
const AboutUsContent = lazy(() => import('./AboutUsContent'));

export default function AboutUs() {

  useEffect(()=>{
      window.scrollTo(0,0);
  },[]);

  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
      <Suspense fallback={<Buffering />}>
        <PageHeader title="About Us" endPoint = "ABOUT US" />
        <AboutUsContent />
      </Suspense>
     </div>
  )
}
