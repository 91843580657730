import React, { lazy, Suspense, useEffect } from "react";
import Buffering from "./Buffering";
// import ThankYouContent from "./ThankYouContent";
// import PageHeader from "./PageHeader";


const PageHeader = lazy(() => import('./PageHeader'));
const ThankYouContent = lazy(() => import('./ThankYouContent'));

export default function ThankYou() {
  useEffect(()=>{
    window.scroll(0,0);
  },[]);
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <Suspense fallback={<Buffering />}>
        <PageHeader title="Thank You" endPoint = "THANKYOU" />
        <ThankYouContent />
      </Suspense>
      
    </div>
  );
}
