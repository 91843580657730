import React, { lazy, Suspense, useEffect } from 'react'
// import NotFoundContent from './NotFoundContent'
// import PageHeader from './PageHeader';
import Buffering from './Buffering';

const PageHeader = lazy(()=>import("./PageHeader"));
const NotFoundContent = lazy(()=>import('./NotFoundContent'));

export default function NotFound() {
  
  useEffect(()=>{
    window.scrollTo(0,0);
},[]);
  return (
    <div className='col-12 col-md-12 col-sm-12 col-lg-12 col-xl-12 row px-0 display-flex'>
      <Suspense fallback={<Buffering />}>
        <PageHeader title="404" endPoint = "404 Not Found" />
        <NotFoundContent />
      </Suspense>
    </div>
  )
}
