import React, { useState } from 'react';
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from 'styled-components';
import BotAvatar from './BotAvatar';

export default function ChatBotWindow(props) {

  const [chatbotStatus , setChatbotStatus] = useState("close");
  
  const steps =[
    {
      id:'1',
      message:"Hello, Welcome to Growth Studio Community!",
      trigger: '2'
    },
    {
      id:'2',
      message: "What's your name?",
      trigger: '3'
    },
    {
      id: '3',
      user : true,
      trigger : '4'
    },
    {
      id:'4',
      message: "What's your phone number?",
      trigger: '5'
    },
    {
      id: '5',
      user : true,
      trigger : '6'
    },
    {
      id:'6',
      message: "What's your email address?",
      trigger: '7'
    },
    {
      id: '7',
      user : true,
      trigger : '8'
    },
    {
      id:'8',
      message: "Please select the Type Of Service!",
      trigger: '9'
    },
    {
      id:'9',
      options :[
        {label: "WEB" , value: "WEB" , trigger: '10'},
        {label: "CREATIVES" , value: "CREATIVES" , trigger: '11'},
        {label: "MEDIA" , value: "MEDIA" , trigger: '12'},
        {label: "EVENT" , value: "EVENT" , trigger: '13'}
      ]
    },
    {
      id:'10',
      message: "Select the Service from WEB Domain!",
      trigger: '10a'
    },
    {
      id: '10a',
      options :[
        {label: "Website Design" , value: "Website Design",trigger: 'finish' },
        {label: "Web Development" , value: "Web Development",trigger: 'finish' },
        {label: "E-commerce Solutions" , value: "E-commerce Solutions",trigger: 'finish' },
        {label: "User Experience (UX) and User Interface (UI) Design" , value: "User Experience (UX) and User Interface (UI) Design" ,trigger: 'finish'}
      ]
    },
    {
      id:'11',
      message: "Select the Service from CREATIVES Domain!",
      trigger: '11a'
    },
    {
      id: '11a',
      options :[
        {label: "Concept development" , value: "Concept development",trigger: 'finish' },
        {label: "Graphic design" , value: "Graphic design",trigger: 'finish' },
        {label: "Copywriting " , value: "Copywriting " ,trigger: 'finish'},
        {label: "Video production" , value: "Video production" },
        {label: "Branding & Identity design" , value: "Branding & Identity design",trigger: 'finish' },
        {label: "Brand strategy & positioning" , value: "Brand strategy & positioning",trigger: 'finish' }
      ]
    },  
    {
      id:'12',
      message: "Select the Service from MEDIA Domain!",
      trigger: '12a'
    },
    {
      id: '12a',
      options :[
        {label: "Media Strategy and Planning" , value: "Media Strategy and Planning", trigger: 'finish' },
        {label: "Media Buying (TV, Radio, Print, Digital, Outdoor)" , value: "Media Buying (TV, Radio, Print, Digital, Outdoor)",trigger: 'finish' },
        {label: "Media Scheduling and Optimization " , value: "Media Scheduling and Optimization ",trigger: 'finish' }
      ]
    },
    {
      id:'13',
      message: "Select the Service from EVENT Domain!",
      trigger: '13a'
    },
    {
      id: '13a',
      options :[
        {label: "Corporate Events" , value: "Corporate Events",trigger: 'finish' },
        {label: "Advertisement Events" , value: "Advertisement Events",trigger: 'finish' }
      ]
    },
    {
      id:'finish',
      message: "Thank You for showing your interest, we'll get back to you as soon as possible!!!",
      end: true
    }
  ];

  const theme = {
    background: '#f0f0f0',
    headerBgColor: 'rgb(250, 67, 67)',
    headerFontColor: '#f0f0f0',
    headerFontSize: '15px',
    botBubbleColor: 'rgb(250, 67, 67)',
    botFontColor: '#f0f0f0',
    userBubbleColor: '#3b3b3b',
    userFontColor: '#f0f0f0',
  };
  async function handleEnd({ steps, values }){
    try {
      
      await fetch("https://growthstudio.co.in/sendEmailFromChatbot.php",{
        method:"post",
        body:JSON.stringify({
          name: values[0],
          phone: values[1],
          email: values[2],
          typeOfService: values[3],
          coreService: values[4]
        })
      })
      .then(async(res)=>{
        res = await res.json();
        if(res.success)
        {
          // console.log(res.success);
        }
        else
        {
          // console.log(res.error);
        }
      })
      .catch(err=>alert("Weak network connectivity!"));

    } catch (error) {
      console.log(error);
        alert("Something went wrong!");
    }
  };

  return (
    <div id='chatbot-container' className='chatbot-screen pt-4 w-auto row px-0 display-flex-align-items-top'>
          <div className="chatbot-toggle-btn col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-2 display-flex-end">
            <button onClick={()=>{
                document.body.style.scroll="scroll";
                document.getElementById('chatbot-container').classList.toggle("active");
                if(chatbotStatus==="open")
                  {
                    setChatbotStatus("close");
                  }
                  else
                  {
                    setChatbotStatus("open");
                  }
                
            }} 
            className='btn-universal-converse w-auto'>
            { 
            chatbotStatus==="open" ? 
            <i class="fa-solid fa-xmark"></i> : 
            "Live Chat"
            }
            </button>
          </div>
          <ThemeProvider theme={theme}>
            <ChatBot 
            headerTitle = {"Growth Agent"} 
            handleEnd={handleEnd} 
            className="mt-0 height-90vh custom-chatbot display-flex" 
            steps = {steps} />
          </ThemeProvider>
    </div>
  )
}
