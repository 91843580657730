import React, { lazy, Suspense, useEffect } from "react";
// import ArticleThreeContent from "./ArticleThreeContent";
import MetaTags from "./MetaTags";
import Buffering from "./Buffering";
// import PageHeader from "./PageHeader";
const PageHeader = lazy(()=>import("./PageHeader"));
const ArticleThreeContent = lazy(()=>import("./ArticleThreeContent"));

export default function ArticleThree() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <MetaTags
        title="The Evolution of Advertising: From Traditional to Digital"
        description="Explore the fascinating journey of advertising from traditional methods like print and radio to the dynamic world of digital and social media. Discover key milestones, trends, and the future of advertising."
        keywords="Evolution of advertising
Traditional vs. digital advertising
History of advertising
Digital advertising trends
Social media advertising
Mobile advertising statistics
Programmatic advertising
Future of advertising
Advertising milestones
Personalized advertising
"
      />
      <Suspense fallback={<Buffering />}>
      <PageHeader title = "The Evolution Of Advertising" endPoint = "Article 3" />
      <ArticleThreeContent />
      </Suspense>
    </div>
  );
}
