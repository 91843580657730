import React, { lazy, Suspense, useEffect } from 'react'
import Buffering from './Buffering';
// import SitemapContent from './SitemapContent'
// import PageHeader from './PageHeader';

const PageHeader = lazy(()=>import("./PageHeader"));
const SitemapContent = lazy(()=>import("./SitemapContent"));

export default function Sitemap() {
  useEffect(()=>{
      window.scrollTo(0,0);
  },[]);

  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
      <Suspense fallback={<Buffering />} >
        
      <PageHeader title="Site Map" endPoint = "SITEMAP" />
        <SitemapContent />
        </Suspense>
    </div>
  )
}
