import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Navbar() {

  let navigate=useNavigate();


  const [homeUnderline, setHomeUnderline] = useState("");
  const [aboutUsUnderline, setAboutUsUnderline] = useState("");
  const [servicesUnderline, setServicesUnderline] = useState("");
  const [ourWorkUnderline, setOurWorkUnderline] = useState("");
  const [contactUsUnderline, setContactUsUnderline] = useState("");
  const [careersUnderline, setCareersUnderline] = useState("");
  const [rotateDropdownArrow, setRotateDropdownArrow] = useState("rotate-0");
  const [displayDropdown, setDisplayDropdown] = useState("height-0");

  const [currentNavbarStatus, setCurrentNavbarStatus] = useState("scale-navbar-0");

//   position-fixed-top
  return (
    <>
    <div className={`font-pt-sans display-on-large-screen nav position-fixed-top m-0 col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 display-flex row p-0`}>
      <div className="shadow-lg bg-navbar py-3 col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 display-flex-between row p-0">
        <div className="col-12 col-sm-12 col-md-4 col-xl-2 col-lg-3 display-flex px-0 ms-3">
          <img
            className="w-100 "
            onClick={()=>navigate("/")}
            src="https://growthstudio.co.in/images/logo.webp"
            alt="Growth Studio Logo"
          />
        </div>

        <div className="col-12 col-sm-12 col-md-7 col-xl-7 col-lg-7 display-flex px-0 m-0">



          <button
            onMouseOver={() =>
              setHomeUnderline("width-100 btn-navbar-hover-underline")
            }
            onMouseLeave={() => setHomeUnderline("")}
            onClick={()=>navigate("/")}
            className="btn-navbar"
          >
            HOME
            <div className={`${homeUnderline} p-0 m-0 height-10px`}></div>
          </button>
          {/* <button className='btn-navbar'>ABOUT US</button> */}
          <button
            onMouseOver={() =>
              setAboutUsUnderline("width-100 btn-navbar-hover-underline")
            }
            onMouseLeave={() => setAboutUsUnderline("")}
            onClick={()=>navigate("/aboutus")}
            className="btn-navbar"
          >
            ABOUT US
            <div className={`${aboutUsUnderline} p-0 m-0 height-10px`}></div>
          </button>
          {/* <button className='btn-navbar'>SERVICES</button> */}
          <button
            onMouseOver={() => {
              setServicesUnderline("width-100 btn-navbar-hover-underline");
            }}
            onMouseLeave={() => {
              setServicesUnderline("");
            }}
            onFocus={() => {
              setRotateDropdownArrow("rotate-180 color-red");
              setDisplayDropdown("");
              //   setServicesUnderline("width-100 btn-navbar-hover-underline");
            }}
            onBlur={() => {
              setRotateDropdownArrow("rotate-0");
              setServicesUnderline("");
              setDisplayDropdown("height-0");
            }}
            className="btn-navbar"
            
            onClick={()=>navigate("/services")}
          >
            SERVICES{" "}
            {/* <i
              className={`${rotateDropdownArrow} transition-half-sec fa-solid fa-angle-down`}
            ></i> */}
            <div className={`${servicesUnderline} p-0 m-0 height-10px`}></div>
          </button>
          {/* <button className='btn-navbar'>PORTFOLIO</button> */}
          <button
            onMouseOver={() =>
              setOurWorkUnderline("width-100 btn-navbar-hover-underline")
            }


            
            onClick={()=>navigate("/ourwork")}
            onMouseLeave={() => setOurWorkUnderline("")}
            className="btn-navbar"
          >
            PORTFOLIO
            <div className={`${ourWorkUnderline} p-0 m-0 height-10px`}></div>
          </button>

          <button
            onMouseOver={() =>
              setContactUsUnderline("width-100 btn-navbar-hover-underline")
            }
            onMouseLeave={() => setContactUsUnderline("")}
            onClick={()=>navigate("/contactus")}
            className="btn-navbar"
          >
            CONTACT 
            <div className={`${contactUsUnderline} p-0 m-0 height-10px`}></div>
          </button>
        </div>
      </div>
      
    </div>

    <div className="position-fixed-top nav-scroll mx-auto col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-on-small-screen z-index-3">
      <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-lg-12 display-flex-start  bg-navbar row px-0 py-2 mx-auto">
          
          <button 
          onClick={()=>{
              setCurrentNavbarStatus("scale-navbar-1");
          }} 
          className="btn-navbar-small-screen">
            <i className="fa-solid fa-bars"></i>
          </button>
          <div className="col-6 col-sm-5 col-md-4 col-lg-4 col-xl-6 display-flex p-0">
            <img 
            src="https://growthstudio.co.in/images/logo.webp" alt="Growth Studio Logo" className="w-100 m-0" />
          </div>
      </div>

      <div className={`${currentNavbarStatus} col-12 col-sm-12 col-md-7 col-lg-6 col-xl-4 row px-0 display-flex-align-items-top pb-5 bg-navbar small-screen-navbar`}>
        <div className="w-100 display-flex-end px-4 ">
          <button
          onClick={()=>{
            setCurrentNavbarStatus("scale-navbar-0");
          }}
          className="btn-navbar-small-screen mt-4">
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <img src="https://growthstudio.co.in/images/logo.webp" className="w-75 px-0 mt-3" 
        alt="Our Logo"
        />
      <button
            onMouseOver={() =>
              setHomeUnderline("width-100 btn-navbar-hover-underline")
            }
            onMouseLeave={() => setHomeUnderline("")}
            onClick={()=>{
              navigate("/")
            setCurrentNavbarStatus("scale-navbar-0");
            }}
            className="btn-navbar w-100 mb-1 mt-3"
          >
            HOME
            <div className={`${homeUnderline} p-0 m-0 height-10px`}></div>
          </button>

          <button
            onMouseOver={() =>
              setAboutUsUnderline("width-100 btn-navbar-hover-underline")
            }
            onMouseLeave={() => setAboutUsUnderline("")}
            onClick={()=>{navigate("/aboutus")
              
            setCurrentNavbarStatus("scale-navbar-0");
            }}
            className="btn-navbar w-100 my-1"
          >
            ABOUT US
            <div className={`${aboutUsUnderline} p-0 m-0 height-10px`}></div>
          </button>
          <button
            onMouseOver={() => {
              setServicesUnderline("width-100 btn-navbar-hover-underline");
            }}
            onMouseLeave={() => {
              setServicesUnderline("");
            }}
            onFocus={() => {
              setRotateDropdownArrow("rotate-180 color-red");
              setDisplayDropdown("");
            }}
            onBlur={() => {
              setRotateDropdownArrow("rotate-0");
              setServicesUnderline("");
              setDisplayDropdown("height-0");
            }}
            className="btn-navbar w-100 my-1"
            onClick={()=>{navigate("/services")
              
            setCurrentNavbarStatus("scale-navbar-0");
            }}
          >
            SERVICES{" "}
            <div className={`${servicesUnderline} p-0 m-0 height-10px`}></div>
          </button>
          <button
            onMouseOver={() =>
              setOurWorkUnderline("width-100 btn-navbar-hover-underline")
            }
            onMouseLeave={() => setOurWorkUnderline("")}
            onClick={()=>{navigate("/ourwork")
              setCurrentNavbarStatus("scale-navbar-0");}}
            className="btn-navbar w-100 my-1"
          >
            PORTFOLIO
            <div className={`${ourWorkUnderline} p-0 m-0 height-10px`}></div>
          </button>

          <button
            onMouseOver={() =>
              setCareersUnderline("width-100 btn-navbar-hover-underline")
            }


            
            onClick={()=>{navigate("/careers")
              setCurrentNavbarStatus("scale-navbar-0");}}
            onMouseLeave={() => setCareersUnderline("")}
            className="btn-navbar"
          >
            CAREERS
            <div className={`${careersUnderline} p-0 m-0 height-10px`}></div>
          </button>
          <button
            onMouseOver={() =>
              setContactUsUnderline("width-100 btn-navbar-hover-underline")
            }
            onMouseLeave={() => setContactUsUnderline("")}
            onClick={()=>{navigate("/contactus")
              setCurrentNavbarStatus("scale-navbar-0");}}
            className="btn-navbar w-100 my-1"
          >
            CONTACT 
            <div className={`${contactUsUnderline} p-0 m-0 height-10px`}></div>
          </button>
      </div>
    </div>
    </>
  );
}
