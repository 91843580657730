import React, { lazy, Suspense, useEffect } from 'react'
import Buffering from './Buffering';
// import ContactUsContent from './ContactUsContent'
// import LocationView from './LocationView'
// import PageHeader from './PageHeader'


const PageHeader = lazy(() => import('./PageHeader'));

const ContactUsContent = lazy(() => import('./ContactUsContent'));
const LocationView = lazy(() => import('./LocationView'));

export default function ContactUs() {
  useEffect(()=>{
    window.scrollTo(0,0);
},[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex row px-0'>
      <Suspense fallback={<Buffering />}>
        <PageHeader title="Contact Us" endPoint = "CONTACT US" />
        <ContactUsContent />
        <LocationView />
      </Suspense>
    </div>
  )
}
