import React, { lazy, Suspense, useEffect } from 'react';
import Buffering from './Buffering';
// import ServicesContent from './ServicesContent';
// import PageHeader from './PageHeader';

const ServicesContent = lazy(()=>import('./ServicesContent'));
const PageHeader = lazy(()=>import('./PageHeader'));

export default function Services() {
  
  useEffect(()=>{
    window.scrollTo(0,0);
},[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
      <Suspense fallback={<Buffering />}>
        
      <PageHeader title="Services" endPoint = "SERVICES" />
        <ServicesContent />

        </Suspense>
    </div>
  )
}
