import React, { lazy, Suspense, useEffect } from 'react';
import Buffering from './Buffering';
// import CareersContent from './CareersContent';
// import LocationView from './LocationView';
// import PageHeader from './PageHeader';

const PageHeader = lazy(()=>import("./PageHeader"));
const CareersContent = lazy(()=>import("./CareersContent"));
const LocationView = lazy(()=>import("./LocationView"));

export default function Careers() {
  
  useEffect(()=>{
    window.scrollTo(0,0);
},[]);
  return (
    <div className='col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 row px-0 display-flex'>
      <Suspense fallback={<Buffering />}>
      <PageHeader title="Careers" endPoint = "CAREERS" />
      <CareersContent />
      <LocationView />
      </Suspense>
    </div>
  )
}
