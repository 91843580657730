import React, { lazy, Suspense, useEffect } from "react";
// import ArticleTwoContent from "./ArticleTwoContent";
import MetaTags from "./MetaTags";
import Buffering from "./Buffering";
// import PageHeader from "./PageHeader";

const PageHeader = lazy(()=>import("./PageHeader"));
const ArticleTwoContent = lazy(()=>import("./ArticleTwoContent"));

export default function ArticleTwo() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <MetaTags
        title="The Importance of Visuals in Advertising: Design Tips and Trends"
        description="Discover the power of visuals in advertising. Learn essential design tips and explore current trends to create impactful ads that capture attention and drive action."
        keywords="Importance of visuals in advertising
Advertising design tips
Trends in advertising visuals
Effective visual ads
Visual hierarchy in ads
Colour psychology in advertising
Minimalist ad designs
Bold typography in advertising
Authentic imagery in ads
Animated visuals in marketing
"
      />
      <Suspense fallback={<Buffering />}>
      <PageHeader title="The Importance of Visuals in Advertising" endPoint = "Article 2" />
      <ArticleTwoContent />
      </Suspense>
    </div>
  );
}
