import React from 'react';
import bufferingAnimation from "./bufferingAnimation.json";
import Lottie from 'lottie-react';

export default function Buffering() {
  return (
    <div className="buffering-container row px-0 display-flex m-0">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12  col-xl-12 display-flex-column px-0">
            
            <img src="https://growthstudio.co.in/images/logo.webp" className='w-50 ' alt="" />
            <Lottie animationData={bufferingAnimation} className="w-25 px-0 mt-5" />
            
          </div>
      </div>
  )
}
