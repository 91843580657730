import React, { lazy, Suspense, useEffect } from "react";
// import ArticleFourContent from "./ArticleFourContent";
import MetaTags from "./MetaTags";
import Buffering from "./Buffering";
// import PageHeader from "./PageHeader";
const PageHeader  = lazy(()=>import("./PageHeader"));
const ArticleFourContent  = lazy(()=>import("./ArticleFourContent"));

export default function ArticleFour() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <MetaTags
        title="The Psychology Behind Successful Advertisements"
        description="Explore the psychological principles that make advertisements effective. Learn how emotional appeal, storytelling, social proof, and more can drive consumer behaviour and create memorable campaigns."
        keywords="Psychology of advertising
        Successful ad strategies
        Emotional appeal in ads
        Advertising psychology principles
        Effective storytelling in marketing
        Social proof in advertising
        Authority in marketing
        Scarcity in advertising
        Reciprocity principle in ads
        Visual appeal in advertisements
        "
      />
      <Suspense fallback={<Buffering />}>
      <PageHeader title = "The Psychology Behind Successful Advertisements" endPoint = "Article 4" />

      <ArticleFourContent />
      </Suspense>

    </div>
  );
}
