import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "./userComponents/HomePage";

import AboutUs from "./userComponents/AboutUs";
import OurWork from "./userComponents/OurWork";
import ContactUs from "./userComponents/ContactUs";
import Careers from "./userComponents/Careers";
import Services from "./userComponents/Services";
import Articles from "./userComponents/Articles";
import Sitemap from "./userComponents/Sitemap";
import Privacy from "./userComponents/Privacy";
import NotFound from "./userComponents/NotFound";
import Navbar from "./userComponents/Navbar";
import StickyButtons from "./userComponents/StickyButtons";
import Footer from "./userComponents/Footer";
import Faqs from "./userComponents/Faqs";
import ArticleOne from "./userComponents/ArticleOne";
import ArticleTwo from "./userComponents/ArticleTwo";
import ArticleThree from "./userComponents/ArticleThree";
import ArticleFour from "./userComponents/ArticleFour";
import ThankYou from "./userComponents/ThankYou";
import { useState } from "react";
import ChatBot from "./userComponents/ChatBotWindow";
import ChatBotWindow from "./userComponents/ChatBotWindow";
import Buffering from "./userComponents/Buffering";

function App() {
  const [showStickyButtons , setShowStickyButtons] = useState(false);
  const [showChatbot , setShowChatbot] = useState(false);

  window.onscroll = function(){
    if(window.scrollY> 500)
      {
        setShowStickyButtons(true);
        setShowChatbot(true);
      }
      else
      {
        setShowStickyButtons(false);
        setShowChatbot(false);
      }
  }

  return (
    <div className="container-fluid row display-flex px-0 m-0">
      
      <Navbar />
      {/* <Buffering /> */}
        <Routes>
          <Route exact path="/*" element={<HomePage />}/>
          <Route path="/thank_you/*" element={<ThankYou />} />
          <Route path="/aboutus/*" element={<AboutUs />} />
          <Route path="/ourwork/*" element={<OurWork />} />
          <Route path="/contactus/*" element={<ContactUs />} />
          <Route path="/careers/*" element={<Careers />} />
          <Route path="/services/*" element={<Services />} />
          <Route path="/sitemap/*" element={<Sitemap />} />
          <Route path="/privacy/*" element={<Privacy />} />
          <Route path="/404/*" element={<NotFound />} />
          <Route path="/faqs/*" element={<Faqs />} />
          <Route path="/articles/*" element={<Articles />} />
          <Route
            path="/articles/integrating_traditional_and_digital_advertising_for_maximum_impact"
            element={<ArticleOne />}
          />
          <Route
            path="/articles/the_importance_of_visuals_in_advertising"
            element={<ArticleTwo />}
          />
          <Route
            path="/articles/the_evolution_of_advertising"
            element={<ArticleThree />}
          />
          <Route
            path="/articles/the_psychology_behind_successful_advertisements"
            element={<ArticleFour />}
          />
        </Routes>
        <Footer />
      

      
      {showStickyButtons===true && <StickyButtons setShowChatbot={setShowChatbot} />}
      {showChatbot===true && <ChatBotWindow setShowChatbot = {setShowChatbot} />}


    </div>
  );
}

export default App;
