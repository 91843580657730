import React, { lazy, Suspense, useEffect } from 'react';
import Buffering from './Buffering';
// import Navbar from './Navbar';
// import MainSection from './MainSection';
// import Experience from './Experience';
// import Counters from './Counters';
// import Projects from './Projects';
// import WhyChooseUs from './WhyChooseUs';
// import Testimonials from './Testimonials';
// import OurProfessionals from './OurProfessionals';
// import Clients from './Clients';
// import News from './News';
// import Footer from './Footer';

const MainSection = lazy(()=>import("./MainSection"));
const Experience = lazy(()=>import("./Experience"));
const Counters = lazy(()=>import("./Counters"));
const Projects = lazy(()=>import("./Projects"));
const WhyChooseUs = lazy(()=>import("./WhyChooseUs"));
const Clients = lazy(()=>import("./Clients"));
const News = lazy(()=>import("./News"));


export default function HomePage() {
  useEffect(()=>{
    window.scrollTo(0,0);
},[]);
  return (
    <div className='font-raleway col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 display-flex row p-0'>
      <Suspense fallback={<Buffering />} >
        <MainSection />
        <Experience />
        <Counters />
        <WhyChooseUs />
        <Projects />
        <Clients />
        <News />
      </Suspense>
    </div>
  )
}
