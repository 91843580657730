import React from 'react';

export default function StickyButtons(props) {
  return (
    <div className='w-auto sticky-container px-0 display-flex-column'>
        <a target='_blank' href="https://wa.me/8999286805" className='ms-auto btn-universal-converse w-auto'>
        <i className="fa-brands fa-whatsapp"></i>
        </a>
    </div>
  )
}
