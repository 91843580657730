import React, { lazy, Suspense, useEffect } from 'react'
import Buffering from './Buffering';
// import PrivacyContent from './PrivacyContent'
// import PageHeader from './PageHeader';

const PageHeader = lazy(()=>import("./PageHeader"));
const PrivacyContent = lazy(()=>import("./PrivacyContent"));

export default function Privacy() {
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
  
  return (
    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex'>
      <Suspense fallback={<Buffering />}>
      
      <PageHeader title="Privacy" endPoint = "PRIVACY" />
        <PrivacyContent />

        </Suspense>
    </div>
  )
}
