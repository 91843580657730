import React, { lazy, Suspense, useEffect } from "react";
// import ArticleOneContent from "./ArticleOneContent";
import MetaTags from "./MetaTags";
import Buffering from "./Buffering";
// import PageHeader from "./PageHeader";

const PageHeader = lazy(()=>import("./PageHeader"));
const ArticleOneContent = lazy(()=>import("./ArticleOneContent"));


export default function ArticleOne() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <MetaTags
        title="Integrating Traditional and Digital Advertising for Maximum Impact"
        description="Discover how to blend traditional and digital advertising strategies for a cohesive, powerful marketing approach. Learn innovative techniques to maximize your reach and impact."
        keywords="Integrating traditional and digital advertising
Traditional vs. digital marketing strategies
Advertising integration techniques
Combining print and online advertising
Effective multi-channel marketing
Unified marketing strategy
Cross-platform advertising
Traditional media in digital age
Coordinated advertising campaigns
Enhancing traditional ads with digital
"
      />
      <Suspense  fallback={<Buffering />} >
      <PageHeader title = "Integrating Traditional and Digital Advertising for Maximum Impact" endPoint = "Article 1" />

      <ArticleOneContent />
      </Suspense>
    </div>
  );
}
