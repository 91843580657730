import React, { Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Buffering from "./Buffering";

export default function Faqs() {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionItems = [
    {
      title: 'What services do you offer?',
      content: `Our agency provides a comprehensive range of advertising services, including digital marketing, social media management, search engine optimization (SEO), pay-per-click (PPC) advertising, content creation, graphic design, video production, and traditional advertising methods like TV, radio, and print ads.`
    },
    {
      title: 'How can advertising benefit my business?',
      content: `Advertising helps increase brand awareness, reach a larger audience, drive traffic to your website or physical location, and ultimately boost sales. Effective advertising can also build customer loyalty and improve your brand’s reputation.`
    },
    {
      title: 'What is the process for starting an advertising campaign with your agency?',
      content: `The process begins with a consultation to understand your business goals and target audience. We then create a customized advertising strategy, develop creative assets, launch the campaign, and continuously monitor and optimize its performance.`
    },
    {
      title: 'How do you measure the success of an advertising campaign?',
      content: `We use various key performance indicators (KPIs) to measure success, including website traffic, conversion rates, click-through rates (CTR), return on investment (ROI), and engagement metrics. Regular reports are provided to keep you updated on the campaign’s performance.`
    },
    {
      title: 'What makes your agency different from others?',
      content: `Our agency stands out due to our personalized approach, experienced team, innovative strategies, and proven track record of successful campaigns. We focus on delivering measurable results and building long-term relationships with our clients.`
    },
    {
      title: 'How much does it cost to run an advertising campaign?',
      content: `The cost of an advertising campaign varies based on factors such as the scope of the campaign, the platforms used, the duration, and the specific services required. We offer tailored packages to fit different budgets and goals.`
    },
    {
      title: 'Can you handle both traditional and digital advertising?',
      content: `Yes, we specialize in both traditional and digital advertising. This integrated approach allows us to create cohesive campaigns that leverage the strengths of each medium for maximum impact`
    },
    {
      title: 'How long does it take to see results from an advertising campaign?',
      content: `The timeline for seeing results can vary depending on the campaign objectives and strategies used. Digital campaigns may show initial results within a few weeks, while traditional campaigns might take longer to gauge effectiveness. Continuous monitoring and optimization help achieve the best outcomes.`
    },
    {
      title: 'Do you work with businesses of all sizes?',
      content: `Yes, we work with a diverse range of businesses, from small startups to large corporations. Our team tailors advertising strategies to meet the unique needs and budgets of each client.`
    },
    {
      title: 'How do you ensure that the advertising content aligns with our brand?',
      content: `We start by thoroughly understanding your brand’s values, voice, and target audience. Our creative team collaborates with you throughout the process to ensure that all advertising content is consistent with your brand identity and messaging.`
    },
    {
      title: 'What platforms do you use for digital advertising?',
      content: `We use a variety of digital platforms including Google Ads, Facebook, Instagram, Twitter, LinkedIn, YouTube, and more. The choice of platform depends on where your target audience is most active and the goals of your campaign.`
    },
    {
      title: 'How do you stay updated with the latest advertising trends?',
      content: `Our team regularly attends industry conferences, participates in professional development courses, and stays informed through industry publications and networks. This ensures that we are always equipped with the latest knowledge and best practices.`
    },
    {
      title: ' Can you provide examples of past successful campaigns?',
      content: `Yes, we can provide case studies and examples of past campaigns that have delivered significant results for our clients. These examples showcase our ability to drive success across various industries and advertising channels.`
    },
    {
      title: 'How do you handle feedback and revisions during a campaign?',
      content: `We value client feedback and work collaboratively to make any necessary adjustments. Regular check-ins and performance reviews ensure that the campaign stays on track and aligns with your expectations.`
    },
    {
      title: 'How can I get started with your advertising agency?',
      content: `To get started, simply contact us through our website, email, or phone. We will schedule an initial consultation to discuss your business needs and how we can help you achieve your advertising goals.`
    }
  ];

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Suspense fallback={<Buffering />}>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
        <div className="height-70vh about-us-bg-image col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
          <div className="col-12 col-sm-12 col-md-9 col-xl-6 col-lg-6 row px-0 display-flex">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex text-white fs-larger fw-bold">
              <h1 className="fw-bold">FAQs</h1>
            </div>

            <div className="font-pt-sans col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex-center text-white  mt-3">
              <a
                href="/growthStudio/"
                onClick={() => navigate("/")}
                className="footer-link-converse mx-3 color-red"
              >
                Home
              </a>
              <span className="mx-3">
                <i className="fa-solid fa-arrow-right-long"></i>
              </span>
              <span className="mx-3 fs-5">FAQs</span>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 py-5 col-md-12 col-lg-12 col-sm-12 col-xl-12 row px-0 display-flex-column">

      <div className="accordion-css m-0 px-0 py-4 col-12 col-sm-11 col-md-10 col-lg-10 col-xl-8 display-flex-column">
      {accordionItems.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-title-css ${activeIndex === index ? 'active' : ''}  display-flex-between`}
            onClick={() => {handleItemClick(index);}}
          >
            <span className="w-auto pe-0 m-0 display-flex-start">{item.title}</span>
            <button className="display-on-large-screen btn-arrow">
                <i className="fa-solid fa-chevron-down"></i>
            </button>
          </div>
          <div className={`accordion-content-css ${activeIndex === index ? 'show' : ''}`}>
            {item.content}
          </div>
        </div>
      ))}
    </div>


      </div>
    </div>
      </Suspense>
    </>
    
  );
}
