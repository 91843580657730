import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import bufferingAnimation from "./bufferingAnimation.json";

export default function Footer() {
  let navigate = useNavigate();

  const [emailForSubscription, setEmailForSubscription] = useState("");
  const [validEmail, setValidEmail] = useState(null);
  const [showBuffering, setShowBuffering] = useState(false);

  const quickLinks = [
    {
      title: "Articles",
      navigateTo: "/articles",
    },
    {
      title: "Careers",
      navigateTo: "/careers",
    },
    {
      title: "Sitemap",
      navigateTo: "/sitemap",
    },
    {
      title: "Privacy",
      navigateTo: "/privacy",
    },
    {
      title: "FAQs",
      navigateTo: "/faqs",
    },
    {
      title: "404 Not Found",
      navigateTo: "/404",
    },
  ];
  
  const hrefLinks = [
    {
      title: "Growth Studio",
      icon: "fa-brands fa-facebook-f",
      hrefLink: "https://www.facebook.com/Advertisinggrowthstudio",
    },
    {
      title: "8999286805",
      icon: "fa-brands fa-whatsapp",
      hrefLink: "https://wa.me/8999286805",
    },
    {
      title: "Growth Studio",
      icon: "fa-brands fa-linkedin",
      hrefLink: "https://www.linkedin.com/company/growthstutio99/",
    },
    {
      title: "growthstudio4",
      icon: "fa-brands fa-instagram",
      hrefLink: "https://www.instagram.com/growthstudio4/",
    },
    {
      title: "+91-8999286805",
      icon: "fa-solid fa-phone",
      hrefLink: "tel:8999286805",
    },
  ];

  return (
    <div className="font-pt-sans bg-black py-5 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex">
      <div className="col-12 col-sm-12 col-lg-12 col-md-12 col-xl-12 row px-0 display-flex-top text-white">
        <div className="col-12 text-white col-sm-12 col-md-6 col-lg-6 col-xl-3 row px-2 display-flex-start py-5">
          <div className="col-12 fs-1 fw-bold text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex-start">
            <span className="color-red w-auto me-3">/</span>{" "}
            <span className="fs-5 w-auto">OFFICE</span>
          </div>

          <div className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ps-5 mt-3">
            Hingne Khurd, Apte Colony, Sinhagad Road, Pune-51
          </div>

          <div className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ps-5 mt-2">
            <span className="w-100 display-flex-start color-red ps-0">
              Support:
            </span>{" "}
            <a
              href="mailto:info@demolink.org"
              className="footer-link w-100 px-0 display-flex-start"
            >
              admin@growthstudio.co.in
            </a>
          </div>

          <div className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ps-5 mt-3">
            <span className="w-100 display-flex-start color-red ps-0">
              Phone:
            </span>{" "}
            <a
              href="mailto:info@demolink.org"
              className="footer-link w-100 px-0 display-flex-start"
            >
              +91-8999286805
            </a>
          </div>
        </div>

        <div className="col-12 text-white col-sm-12 col-md-6 col-lg-6 col-xl-3 row px-2 display-flex-start py-5">
          <div className="col-12 fs-1 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex-start">
            <span className="color-red w-auto fw-bold me-3">/</span>{" "}
            <span className="fs-5 w-auto">Subscribe Now</span>
          </div>

          <div className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ps-5 mt-3">
            Get the latest updates and offers
          </div>

          <div className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-column ps-5 mt-2">
            <input
              type="text"
              className="footer-input-field w-100 "
              placeholder="Enter your email"
              onChange={(event) => {
                setEmailForSubscription(event.target.value);
              }}
            />
            <div className="height-40px color-red w-100 m-0 px-0 display-flex-start fw-light fs-small">
              {/* {validEmail === false && "*Invalid Email Format"}
              {validEmail === null && "*compulsory"} */}
            </div>
          </div>
          <div className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ps-5 mt-2">
            <button
              onClick={async () => {
                if (
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    emailForSubscription
                  ) === true ||
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z]+\.[a-zA-Z]{2,3}\.[a-zA-Z]{2,3}$/.test(
                    emailForSubscription
                  ) === true
                ) {
                  try {
                    //fetch api codeblock
                    setValidEmail(true);
                    setShowBuffering(true);
                    await fetch(
                      "https://growthstudio.co.in/sendEmailForSubscription.php",
                      {
                        method: "post",
                        body: JSON.stringify({ email: emailForSubscription }),
                        headers: {
                          "Content-Type": "application/json",
                        },
                      }
                    )
                      .then(async (res) => {
                        res = await res.json();
                        setShowBuffering(false);
                        if (res.success) {
                          // alert("We'll contact you shortly!");
                          navigate("/thank_you");
                        } else {
                          alert(
                            "Error while sending your response, please check your internet or try again later!"
                          );
                          window.location.reload();
                        }
                      })
                      .catch((err) => {
                        alert("Something went wrong");
                        setShowBuffering(false);
                        window.location.reload();
                      });
                  } catch (error) {
                    alert("Error while communicating with server!");
                    setShowBuffering(false);
                    window.location.reload();
                  }
                } else {
                  setValidEmail(false);
                }
              }}
              className="btn-universal-converse m-0 w-100 hover-red-border"
            >
              SEND
            </button>
          </div>
        </div>

        <div className="col-12 text-white col-sm-12 col-md-6 col-lg-6 col-xl-2 row px-2 display-flex-start py-5">
          <div className="col-12 fs-1 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex-start">
            <span className="color-red w-auto fw-bold me-3">/</span>{" "}
            <span className="fs-5 w-auto">Quick Links</span>
          </div>

          {quickLinks.map((ele, index) => (
            <div
              key={index}
              className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ps-5 mt-3"
            >
              <button
                onClick={() => navigate(ele.navigateTo)}
                className="btn-social-media-icons display-flex-start ps-0 fs-6 transition-half-sec m-0 w-100"
              >
                {ele.title}
              </button>
            </div>
          ))}
        </div>

        <div className="col-12 text-white col-sm-12 col-md-6 col-lg-6 col-xl-3 row px-2 display-flex-start py-5">
          <div className="col-12 fs-1 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 display-flex-start">
            <span className="color-red w-auto fw-bold me-3">/</span>{" "}
            <span className="fs-5 w-auto">Social Media Links</span>
          </div>

          {hrefLinks.map((ele, index) => (
            <div key={index} className="col-12 text-white col-sm-12 col-md-12 col-lg-12 col-xl-12 row px-0 display-flex-start ps-5 mt-3">
              <a
                href={ele.hrefLink}
                target="_blank" rel="noreferrer"
                className="btn-social-media-icons text-decoration-none fs-6 transition-half-sec m-0 w-100"
              >
                <i className={ele.icon}></i> : {ele.title}
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-xl-12 col-lg-12 display-flex-center fs-small text-white font-pt-sans">
        Copyright <i className="fa-regular fa-copyright mx-1"></i> 2024 GROWTH
        STUDIO
      </div>
      {showBuffering === true && (
        <div className="buffering row px-0 display-flex m-0">
          <div className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-2 display-flex px-0">
            <Lottie
              animationData={bufferingAnimation}
              className="w-100 px-0 m-0"
            />
          </div>
        </div>
      )}
    </div>
  );
}
